import { ModalBasic, Logic, MultiSetMenu, BasicSelect } from '@root/components';
import { useState, useMemo } from 'react';
import styled from 'styled-components';

import { TextField } from '@mui/material';

const TYPE_PLATFORM = [
  { name: 'Learning Apps', _id: 'learningapps' },
  { name: 'Wordwall', _id: 'wordwall' },
  { name: 'H5P', _id: 'h5p' },
];

export const GameLinkForm = ({ handleClose }) => {
  const [link, setLink] = useState('');
  const [type, setType] = useState('');

  const res = useMemo(() => {
    if (link === '' || type === "") return null
    const regex = type === 'learningapps' ? /app=([a-zA-Z0-9]{6,})/ : /embed\/([a-zA-Z0-9]{6,})/;
    const match = link.match(regex);
    console.log(match);

    if (match) {
      return `https://school.lesia-portuguese.com/games/${type}?id=${match[1]}`
    } else {
      return 'ID не найден';
    }
  }, [link, type]);


  return (
    <ModalBasic handleClose={handleClose}>
      <FlexContainer>
        <Form>
          <Title>Constructor for create games links</Title>

          <BasicSelect
            data={TYPE_PLATFORM}
            currentId={type}
            name={'Subscription'}
            fetchValue={value => setType(value)}
            sx={{ width: '100%', margin: '10px 0' }}
          />
          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label="Link of games"
            variant="outlined"
            name="link"
            value={link}
            onInput={e => {
              setLink(e.target.value);
            }}
            size="small"
            margin="dense"
          />
          {
            res && (
              <Label>
                <Text>Result link</Text>
                <Result>{res}</Result>
              </Label>
            )
          }

        </Form>
      </FlexContainer>
    </ModalBasic>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;

  margin-top: 10px;

  button {
    margin-top: 10px;
  }
`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;
const Result = styled.p``;
const Text = styled.span`
  font-weight: 600;
`;